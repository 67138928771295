/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

// Array of sections
import rule from '@/router/routes/rule'
import logout from './logout'
import quiz from './quiz'
import staff from './staff'
// import training from './training'
// import pressroom from './pressroom'
import project from './project'
import dashboard from './dashboard'
import file from './file'

export default [...dashboard, ...project, ...quiz, ...rule, ...file, ...staff, ...logout]
