export default [
  {
    header: ' ',
  },
  {
    title: 'Logout',
    icon: 'LogOutIcon',
    route: 'logout',
    resource: 'Auth',
  },
]
