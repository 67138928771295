export default [
  {
    title: 'Training',
    icon: 'BookOpenIcon',
    route: 'training.list',
    resource: 'Auth',
  },
  {
    title: 'Pressroom',
    icon: 'FileIcon',
    route: 'pressroom.list',
    resource: 'Auth',
  },
]
